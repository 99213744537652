<template>
    <div class="row w-100" style="height: 100dvh;">
        <div class="col-6 bg-dark2 text-white h-100 d-flex justify-content-center align-items-center">
            <div class="position-relative d-flex gap-5 align-items-center">
                <img src="../assets/logo.svg" alt="logo" height="80px">
                <div>
                    <h1>Masuk Kelas</h1>
                    <p class="fw-light">Daftar jika belum ada akun</p>
                    <router-link class="text-white text-decoration-none" to="/daftar">Daftar</router-link>
                    <span class="mx-2">|</span>
                    <router-link class="text-white text-decoration-none" to="/">Kembali ke awal</router-link>
                </div>
            </div>
        </div>
        <div class="col-6 h-100 d-flex align-items-center px-5">
            <div class="w-75">
                <form @submit.prevent="login">
                    <div class="mb-3">
                        <label for="email" class="form-label">Email address</label>
                        <input type="email" v-model="form.email" class="form-control" id="email" required>
                    </div>
                    <div class="mb-3">
                        <label for="password" class="form-label">Password</label>
                        <input type="password" v-model="form.password" class="form-control" id="password" required>
                    </div>
                    <button type="submit" class="btn btn-neon text-white me-2" :disabled="loading">
                        <span v-if="loading" class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        <span v-if="!loading">Login</span>
                        <span v-else>Logging in...</span>
                    </button>
                </form>
                <div v-if="errors" class="alert alert-danger mt-3">
                    {{ errors }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "LoginView",
    data() {
        return {
            form: {
                email: "",
                password: ""
            },
            errors: null,
            loading: false
        };
    },
    methods: {
        async login() {
            this.errors = null;
            this.loading = true;

            try {
                // Send login request to API
                const response = await axios.post("https://api.genbox.academy/public/api/login", {
                    email: this.form.email,
                    password: this.form.password
                });

                if (response.status === 200) {
                    const token = response.data.token;
                    const role = response.data.role;
                    
                    // Save token in local storage
                    localStorage.setItem("auth_token", token);

                    // Redirect based on role
                    if (role === "Admin") {
                        this.$router.push("/admin/dashboard");
                    } else {
                        this.$router.push("/dashboard");
                    }
                }
            } catch (error) {
                // Handle errors from the API
                if (error.response && error.response.data) {
                    this.errors = error.response.data.message || "Login failed. Please try again.";
                } else {
                    this.errors = "An error occurred. Please try again.";
                }
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped>
.bg-dark2 {
    background-image: url(../assets/btcmp.jpg);
    background-position: 50%;
    background-size: cover;
    position: relative;
    z-index: 99;
}

.bg-dark2:before {
    content: "";
    position: absolute;
    display: block;
    background-color: #222831fb;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
}
</style>
