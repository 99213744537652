<template>
    <div class="row bg-light m-0 p-0 w-100 overflow-hidden" style="height: 100dvh;">
        <!-- Sidebar -->
        <SidebarAdmin />

        <!-- Main Content -->
        <div class="col m-0 p-0 h-100 overflow-y-scroll">
            <!-- Navbar -->
            <nav class="navbar bg-white border-bottom">
                <div class="container d-flex justify-content-between">
                    <span class="navbar-brand mb-0 h1">
                        <img src="../../assets/logo.svg" alt="logo" height="30px" class="me-2">
                        <b>Genbox</b><span class="fw-light">Admin</span>
                    </span>
                    <button @click="logout" class="btn btn-outline-danger">Logout</button>
                </div>
            </nav>

            <!-- Content Placeholder -->
            <div class="container p-4">
                <h2>List of Module Groups</h2>

                <!-- Loading Spinner -->
                <div v-if="loading" class="text-center my-5 d-flex align-items-center">
                    <span class="spinner-border me-2" role="status" aria-hidden="true"></span>
                    <span>Loading...</span>
                </div>

                <!-- Module Groups List -->
                <div v-else>
                    <div v-for="group in moduleGroups" :key="group.id" class="card mb-3">
                        <div class="card-header d-flex justify-content-between align-items-center">
                            <div>
                                <h5 class="card-title m-0">{{ group.name }}</h5>
                                <p class="text-muted m-0 mb-3">{{ group.desc }}</p>
                                <span class="badge bg-primary">Price: IDR  {{ group.price }}</span>
                            </div>
                            <button @click="confirmDeleteModuleGroup(group.id)" class="btn btn-lg btn-outline-danger">
                                <i class="fa-solid fa-trash"></i>   
                            </button>
                        </div>
                        <div class="card-body">
                            <h6>Modules:</h6>
                            <ul class="list-group">
                                <li v-for="module in group.modules" :key="module.id" class="list-group-item d-flex justify-content-between align-items-center">
                                    <div>
                                        <strong>{{ module.name }}</strong>
                                        <p class="m-0">{{ module.desc }}</p>
                                    </div>
                                    <button @click="confirmDeleteModule(module.id, group.id)" class="btn btn-lg btn-outline-danger">
                                        <i class="fa-solid fa-trash"></i>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SidebarAdmin from "@/components/SidebarAdmin.vue";
import axios from "axios";
import Swal from "sweetalert2";

export default {
    components: {
        SidebarAdmin
    },
    data() {
        return {
            moduleGroups: [],
            loading: true,
        };
    },
    methods: {
        async fetchModuleGroups() {
            try {
                const response = await axios.get("https://api.genbox.academy/public/api/module-groups");
                this.moduleGroups = response.data;
            } catch (error) {
                console.error("Error fetching module groups:", error);
            } finally {
                this.loading = false;
            }
        },
        confirmDeleteModuleGroup(groupId) {
            Swal.fire({
                title: "Are you sure?",
                text: "This will delete the entire module group and its modules!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Yes, delete it!"
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await this.deleteModuleGroup(groupId);
                }
            });
        },
        async deleteModuleGroup(groupId) {
            try {
                await axios.delete(`https://api.genbox.academy/public/api/module-groups/${groupId}`);
                this.moduleGroups = this.moduleGroups.filter(group => group.id !== groupId);
                Swal.fire("Deleted!", "Module group has been deleted.", "success");
            } catch (error) {
                console.error("Error deleting module group:", error);
                Swal.fire("Error", "Could not delete module group. Please try again.", "error");
            }
        },
        confirmDeleteModule(moduleId, groupId) {
            Swal.fire({
                title: "Are you sure?",
                text: "This will delete the module!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Yes, delete it!"
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await this.deleteModule(moduleId, groupId);
                }
            });
        },
        async deleteModule(moduleId, groupId) {
            try {
                await axios.delete(`https://api.genbox.academy/public/api/modules/${moduleId}`);
                const group = this.moduleGroups.find(group => group.id === groupId);
                if (group) {
                    group.modules = group.modules.filter(module => module.id !== moduleId);
                }
                Swal.fire("Deleted!", "Module has been deleted.", "success");
            } catch (error) {
                console.error("Error deleting module:", error);
                Swal.fire("Error", "Could not delete module. Please try again.", "error");
            }
        },
        logout() {
            localStorage.removeItem("auth_token");
            this.$router.push("/login");
        }
    },
    created() {
        this.fetchModuleGroups();
    }
};
</script>
