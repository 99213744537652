<template>
    <div class="row bg-light m-0 p-0 w-100 overflow-hidden" style="height: 100dvh;">        
        <SidebarAdmin/>

        <!-- Main Content -->
        <div class="col m-0 p-0 h-100">
            <!-- Navbar -->
            <nav class="navbar bg-white border-bottom">
                <div class="container d-flex justify-content-between">
                    <span class="navbar-brand mb-0 h1">
                        <img src="../../assets/logo.svg" alt="logo" height="30px" class="me-2">
                        <b>Genbox</b><span class="fw-light">Admin</span>
                    </span>
                    <button @click="logout" class="btn btn-outline-danger">Logout</button>
                </div>
            </nav>
            <!-- Content Placeholder -->
            <div class="container p-4">
                <h2 class="text-muted fw-light">Belum ada Users yang ter-record</h2>
                <!-- Content goes here -->
            </div>
        </div>
    </div>
</template>

<script>
import SidebarAdmin from "@/components/SidebarAdmin.vue";

export default {
    data() {
        return {
            isCollapsed: false
        };
    },
    components: {
        SidebarAdmin
    },
    methods: {
        toggleCollapse() {
            this.isCollapsed = !this.isCollapsed;
        },
        logout() {
            // Clear auth token or handle logout logic here
            localStorage.removeItem("auth_token");
            this.$router.push("/login");
        }
    }
};
</script>

<style>
.sidebar {
    width: 250px;
}

.sidebar.collapsed {
    width: 80px !important;
}

.bg-light {
    background-color: #f8f9fa !important;
}

.nav-link {
    font-size: 1.1rem;
}

button i {
    font-size: 1.2rem;
}

.navbar-brand img {
    height: 30px;
}
</style>