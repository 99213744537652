<template>
  <div class="home">
    <div class="head"> 
      <nav className="navbar navbar-expand-lg navbar-dark border border-0 border-bottom border-custom">
        <div className="container">
            <h3 className='navbar-brand fs-6 d-flex align-items-center'>
                <img src="../assets/logo.gif" className="me-2" alt="genbox" height="28px" />
                Genbox <span className='fw-light'>Academy</span>
            </h3>
            <router-link to="/login" class="btn btn-neon">Login</router-link>
        </div>
      </nav>
      <nav className="navbar navbar-dark navbar-expand-lg">
          <div className="container">
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                      <li className="nav-item">
                          <a className="nav-link" aria-current="page" href="https://genbox.id">Enterprise</a>
                      </li>
                      <li className="nav-item">
                          <a className="nav-link" aria-current="page" href="https://medium.com/@risanussy07">Article</a>
                      </li>
                      <li className="nav-item">
                          <a className="nav-link" aria-current="page" href="mailto:hello@genbox.id">
                              <i class="fa-regular fa-envelope me-2"></i>
                              hello@genbox.id
                          </a>
                      </li>
                  </ul>
              </div>
          </div>
      </nav>
      <div class="container" style="padding-top: 10vh;">
        <p class="m-0 p-0 text-neon fs-sub-brand">Belajar dan jadilah bagian dari inovasi</p>
        <h1 class="m-0 p-0 text-white fs-brand"  ><span class="blink">Gen</span>eration Out
        <br/>of The <span class="blink">Box</span>.</h1>
        <br>
        <br>
        <br>
        <router-link to="daftar" class="btn btn-main btn-lg me-2">Daftar Sekarang</router-link>
        <button class="btn btn-lg text-white">Tanya di Whatsapp <i class="fa-solid fa-arrow-right ms-2"></i></button>
        <br>
        <h5 class="text-secondary fw-light mt-3">Sudah Punya akun? <router-link class="text-secondary fw-light text-decoration-none" to="/login">Login</router-link></h5>
    </div>
    </div>
    <img src="../assets/cover.png" alt="cover" class="cover-model">
  </div>  
  <div class="container pt-4">

    <div class="shadow bg-white w-100 p-5 rounded">
      <div class="row">
        <div class="col-md-6 border-end">
          <h3>Branding Dirimu<br>Bersama Genbox Academy</h3>
          <small>Belajar mulai dari nol, tanpa harus memiliki background IT</small>
        </div>
        <div class="col-2 border-end text-center">
          <h1 class="text-neon-2">288+</h1>
          <h6>Member</h6>
        </div>
        <div class="col-2 border-end text-center">
          <h1 class="text-neon-2">100+</h1>
          <h6>Project</h6>
        </div>
        <div class="col-2 text-center">
          <h1 class="text-neon-2">2000+</h1>
          <h6>Jam Berlangsung</h6>
        </div>
      </div>
    </div>
    <br>
    <br>
    <br>
    <div class="mt-5">
      <h3 class="text-center">Kenapa Pilih di Genbox Academy?</h3>
      <div class="row mt-5 mb-4">
        <div class="col-md-6">
          <div class="row">
            <div class="col-2">
              <div class="rounded bg-neon box-icon">
                <i class="fa-solid fa-building"></i>
              </div>
            </div>
            <div class="col-10">
              <h5 class="fw-bold">Job Connector</h5>
              <p>Genbox juga berkerja sama dengan beberapa perusahaan yang ada di Indonesia sehingga talent Berkesempatan disalurkan kerja.</p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-2">
              <div class="rounded bg-neon box-icon">
                <i class="fa-solid fa-briefcase"></i>
              </div>
            </div>
            <div class="col-10">
              <h5 class="fw-bold">Sharing Project</h5>
              <p>Genbox juga software house sehingga talent dapat kontribusi bersama sebagai freelance/part time/developer kontrak kami sendiri.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-2">
              <div class="rounded bg-neon box-icon">
                <i class="fa-solid fa-gear"></i>
              </div>
            </div>
            <div class="col-10">
              <h5 class="fw-bold">memiliki Beragam Program</h5>
              <p>Genbox menyediakan banyak program untuk semua kalangan sehingga talent dapat memilih program sesuai kebutuhannya.</p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-2">
              <div class="rounded bg-neon box-icon">
                <i class="fa-solid fa-building"></i>
              </div>
            </div>
            <div class="col-10">
              <h5 class="fw-bold">Kurikulum Terupdate</h5>
              <p>Kurikulum yang kami pakai menyesuaikan keadaan dan kebutuhan di dunia industri yang sedang berjalan di masa kini.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <br>
    <br>
  </div>
  <div class="bg-neon2 py-5 mt-5">
      <center><h3>Pilihan Program Genbox</h3></center>
      <br />
      <div className="cp d-flex">
        <div className="pro-card">
          <div className="cover cvr-1">
            <img src="../assets/cover/btcmp.png" />
          </div>
          <div className="caption">
            <small>Belajar menjadi fullstack dev Website selama 2 bulan full.</small>
          </div>
        </div>
        <div className="pro-card">
          <div className="cover cvr-2">
            <img src="../assets/cover/reg.png" />
          </div>
          <div className="caption">
            <small>Pembelajaran singkat, padat, dan menjuru ke satu materi.</small>
          </div>
        </div>
        <div className="pro-card">
          <div className="cover cvr-3">
            <img src="../assets/cover/prvt.png" />
          </div>
          <div className="caption">
            <small>Pembelajaran face to face langsung dengan mentor</small>
          </div>
        </div>
      </div>
  </div>
  <div class="container">
    <div class="my-5">
      <MarqueeLang />
    </div>
    <div class="row mb-4"><div class="col-sm d-flex align-items-center"><div class="container-fluid"><h2>Pilihan Metode Belajar Genbox</h2><p>Pilihan pembelajaran yang bervariasi agar bisa menyesuaikan kondisi dan kebutuhanmu untuk belajar</p></div></div><div class="col-sm"><div class="card shadow text-center py-5 border-0 rounded"><h5>Onsite di Depok Kota</h5></div></div></div>
    <div class="row"><div class="col-sm mb-4"><div class="card shadow text-center py-5 border-0 rounded"><h5>Online Via Zoom Meet</h5></div></div><div class="col-sm"><div class="card shadow text-center py-5 border-0 rounded"><h5>Video Materi Pelatihan</h5></div></div></div>
  </div>
  <br>
  <br>
  <div class="container mt-5 py-5 bg-calm">
  <h4 class="text-center mb-4 position-relative" style="z-index:99;">Apa kata alumni Genbox?</h4>
  <div class="d-flex justify-content-center gap-3 py-5 position-relative resp-column" style="z-index:99;">
    <!-- Testimonial Cards -->
    <div class="card p-3 d-flex flex-column justify-content-between" style="width:300px;height:300px;">
      <div>
        <h1>"</h1>
        <small class="px-4 d-flex resp-test">Di Genbox ini seru dan tidak kaku, pembimbingan step-by-step jelas dan mudah dipahami, dan jawaban dari setiap konsultasi sangat membantu.</small>
        <h1 class="text-end">"</h1>
      </div>
      <div class="d-flex align-items-center gap-2">
        <div class="rounded-circle overflow-hidden d-flex justify-content-center align-items-center" style="width:40px;height:40px;">
          <img src="../assets/test/prvt.jpeg" alt="testimoni" style="width:110%;">
        </div>
        <small class="text-muted">@fajarfernandi | Peserta Privat</small>
      </div>
    </div>
    <div class="card p-3 d-flex flex-column justify-content-between" style="width:300px;height:300px;">
      <div>
        <h1>"</h1>
        <small class="px-4 d-flex resp-test">Genbox paling jempol dah. Materinya baik, penjelasannya terbaik, mentornya menarik, yuk ikutan juga ✊🏼</small>
        <h1 class="text-end">"</h1>
      </div>
      <div class="d-flex align-items-center gap-2">
        <div class="rounded-circle overflow-hidden d-flex justify-content-center align-items-center" style="width:40px;height:40px;">
          <img src="../assets/test/reg.jpg" alt="testimoni" style="width:110%;">
        </div>
        <small class="text-muted">@Elisabeth | Peserta Bootcamp</small>
      </div>
    </div>
    <div class="card p-3 d-flex flex-column justify-content-between" style="width:300px;height:300px;">
      <div>
        <h1>"</h1>
        <small class="px-4 d-flex resp-test">Pembelajarannya tidak kaku, mentornya baik dan tidak sombong, walaupun 3 bulan tidak membosankan.</small>
        <h1 class="text-end">"</h1>
      </div>
      <div class="d-flex align-items-center gap-2">
        <div class="rounded-circle overflow-hidden d-flex justify-content-center align-items-center" style="width:40px;height:40px;">
          <img src="../assets/test/btcmp.jpg" alt="testimoni" style="width:110%;">
        </div>
        <small class="text-muted">@Syafiq | Peserta Bootcamp</small>
      </div>
    </div>
  </div>
</div>

<div class="container my-5 py-5 d-flex align-items-center flex-column">
  <h1 class="fw-light text-center">Yuk, Tunggu apa lagi? Mulai <b>karirmu</b><br> di Dunia Digital, Bersama <b>Genbox Academy</b></h1>
  <a href="https://chat.whatsapp.com/Lhi15sSEWqoArnopX5oHPO" class="btn btn-lg btn-neon text-white w-25 resp-w25 mt-4">Join Komunitas</a>
</div>

<div class="bg-sans p-5">
  <div class="container">
    <div class="row">
      <div class="col-md">
        <iframe width="100%" height="350" src="https://www.youtube.com/embed/zrhJZHNDxaU?si=VMDBGafySc8YOEAi" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen=""></iframe>
      </div>
      <div class="col-md">
        <h1 class="fw-light">Untuk kamu yang terkendala biaya bisa support channel Genbox</h1>
        <p>Materi didalamnya sama seperti materi bootcamp hanya saja tidak ada sesi live meet, support agar ada konten lainnya juga</p>
        <a href="https://youtu.be/adIBZW9TJ_I?si=0BWbEZlEOp760AYY" class="btn btn-lg btn-neon text-white w-75 mt-4">Langganan Sekarang <i class="fa-brands fa-youtube ms-2"></i></a>
      </div>
    </div>
  </div>
</div>

<footer class="bg-neon2">
  <div class="container py-5">
    <div class="row">
      <div class="col-sm-2 mb-4">
        <h3 class="fs-5">Partner</h3>
        <ul class="mt-3 list-group" style="list-style: none;">
          <li class="mb-1">Pollux Integral</li>
          <li class="mb-1">CRUD Studio</li>
          <li class="mb-1">Kanneza Technology</li>
          <li class="mb-1">Stara Enterprise</li>
          <li class="mb-1">Astrajingga</li>
        </ul>
      </div>
      <div class="col-sm-2 mb-4">
        <h3 class="fs-5">Other Service</h3>
        <ul class="mt-3 list-group" style="list-style: none;">
          <li class="mb-1">Human Resource</li>
          <li class="mb-1">Software Development</li>
          <li class="mb-1">IoT Development</li>
          <li class="mb-1">IT Consultant</li>
        </ul>
      </div>
      <div class="col-sm-2 mb-4 pe-5">
        <h3 class="fs-5">Contact us</h3>
        <ul class="mt-3 list-group" style="list-style: none;">
          <li class="mb-1"><a href="mailto:hello@genbox.id" class="text-decoration-none text-dark">hello@genbox.id</a></li>
          <li><a href="https://wa.me/6289509589977" class="text-decoration-none text-dark">62 895 0958 9977</a></li>
        </ul>
        <h3 class="fs-5 mt-4">Sosmed</h3>
        <ul class="mt-3 list-group" style="list-style: none;">
          <li class="mb-1 d-flex align-items-center">
            <a href="https://www.instagram.com/genbox.id/" class="text-decoration-none text-dark fs-3"><i class="fa-brands fa-instagram"></i></a>
            <a href="https://web.facebook.com/profile.php?id=61553450249679" class="text-decoration-none text-dark fs-4 ms-3"><i class="fa-brands fa-facebook"></i></a>
            <a href="#" class="text-decoration-none text-dark fs-4 ms-3"><i class="fa-brands fa-discord"></i></a>
            <a href="#" class="text-decoration-none text-dark fs-4 ms-3"><i class="fa-brands fa-twitter"></i></a>
          </li>
        </ul>
      </div>
      <div class="col-sm-6 mb-4">
        <div class="row">
          <div class="col-sm">
            <h3 class="fs-5">Genbox Enterprise</h3>
            <h6 class="mb-3">PT Generasi Digital Nasional</h6>
            <p>Jl. Kebagusan II No.32, RT.9/RW.6, Kebagusan, Ps. Minggu, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12520</p>
            <small class="mt-4 d-flex">© Copyright 2024 Genbox</small>
          </div>
          <div class="col-sm">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.674479055595!2d106.82666761468626!3d-6.306428895435859!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69edb7a3004d11%3A0x3af7d406f0678521!2sGenbox%20Academy!5e0!3m2!1sid!2sid!4v1678335105427!5m2!1sid!2sid" width="100%" height="200px" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style="border: 0px;"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
</template>

<script>
import MarqueeLang from "../components/MarqueeLang.vue"

export default {
  name: 'HomeView',
  components: {
    MarqueeLang
  }
}
</script>

<style>
.bg-sans {
    background-color: #d3fff8;
}

.bg-calm {
    background-image: url(../assets/btcmp.jpg);
    background-position: 50%;
    background-size: cover;
    position: relative
}

.bg-sans {
    background-color: #d3fff8
}

.bg-calm:after {
    content: "";
    position: absolute;
    display: block;
    background-color: #32e0c4de;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
}

.btn-neon {
    background-color: #32e0c4 !important;
}

.btn-neon {
    transition: .5s;
}

.btn-neon:hover {
    background-color: #32e0c4;
    cursor: pointer;
    box-shadow: 0 0 28px rgba(50,224,196,.75);
}

.cover-model {
  position: absolute;
  right: 100px;
  bottom: 0;
  z-index: 1;
}

.head {
  z-index: 99;
  position: relative;
}

.home {
  background-color: #222830;
  background-image: url(../assets/kotak.svg);
  width: 100%;
  height: 100vh;
  position: relative;
}

.home:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(50% 50% at 50% 50%,rgba(34,40,49,0) 37.5%,#222831 100%);
}

.border-custom {
  border-color: #3d3d3d !important;
}

.text-neon {
  color : #32e0c4;
  text-shadow: 0 0 8px #32e0c3b7;
}

.text-neon-2 {
  color : #32e0c4;
}

.bg-neon {
  background-color : #32e0c4;
  box-shadow: 0 0 14px #32e0c3b7;
}

.bg-neon2 {
  background-color : #32e0c4;
}

.box-icon {
  width: 60px;
  height: 60px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
}

.btn-main {
    border: 1px solid transparent;
    background-clip: padding-box!important;
    background: linear-gradient(165deg,#fff 65%,hsla(0,0%,100%,.43));
    text-fill-color: transparent;
    transition: .5s
}

.btn-main:hover {
    border: 1px solid #fff;
    color: white;
    background: transparent!important
}

.fs-brand {
  font-size: 74px;
  font-weight: bold;
}

.fs-sub-brand {
  font-size: 24px;
  font-weight: 200;
}

.blink {
  animation: blink 5s infinite;
}

@keyframes blink {
  0% { text-shadow: 0 0 12px #ffffff5b; }
  50% { text-shadow: 0 0 6 #ffffff5b;; }
  100% { text-shadow: 0 0 12px #ffffff5b; }
}

.cp {
    width: 100%;
    flex-wrap: wrap;
    gap: 50px 45px;
    justify-content: center;
}

.pro-card {
    width: 275px;
    height: 270px;
    background-color: white;
    border-radius: 8px;
}

.cover {
    width: 100%;
    height: 68%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
}

.cover img {
    height: 110%;
}

.cover:after {
    position: absolute;
    font-size: 32px;
    text-shadow: 0px 2px rgba(34, 40, 49, 0.75);
    top: 0;left: 0;right: 0;bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    background-color: rgba(34, 40, 49, 0.75);;
}

.cvr-1:after {
    content: 'Bootcamp';
}

.cvr-2:after {
    content: 'Reguler';
}

.cvr-3:after {
    content: 'Private';
}

.caption {
    width: 100%;
    height: 32%;
    padding: 10px 20px;
}
</style>