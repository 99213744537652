<template>
    <div class="row bg-light m-0 p-0 w-100 overflow-hidden" style="height: 100vh;">
        <SidebarAdmin />

        <!-- Main Content -->
        <div class="col m-0 p-0 h-100 overflow-y-scroll">
            <!-- Navbar -->
            <nav class="navbar bg-white border-bottom">
                <div class="container d-flex justify-content-between">
                    <span class="navbar-brand mb-0 h1">
                        <img src="../../assets/logo.svg" alt="logo" height="30px" class="me-2">
                        <b>Genbox</b><span class="fw-light">Admin</span>
                    </span>
                    <button @click="logout" class="btn btn-outline-danger">Logout</button>
                </div>
            </nav>

            <!-- Content Placeholder -->
            <div class="container p-4">
                <h2 class="mb-4">Data Genbox</h2>

                <div class="row">
                    <!-- Chart and Participant List -->
                    <div class="col-md-8">
                        <div class="card p-4 mb-4">
                            <h5>Peserta</h5>
                            <!-- Chart Section -->
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <canvas id="educationChart" style="max-width: 450px;max-height: 345px !important;"></canvas>
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item">Sekolah Akhir</li>
                                    <li class="list-group-item">Sekolah Kejuruan</li>
                                    <li class="list-group-item">Diploma</li>
                                    <li class="list-group-item">Sarjana</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <!-- Summary Cards -->
                    <div class="col-md-4">
                        <div class="card text-bg-primary bg-opacity-10 text-primary mb-3">
                            <div class="card-body">
                                <h5 class="card-title">Jumlah User</h5>
                                <p class="card-text">{{ totalUsers }} / Orang</p>
                            </div>
                        </div>
                        <div class="card text-bg-success bg-opacity-10 text-success mb-3">
                            <div class="card-body">
                                <h5 class="card-title">Jumlah Pemasukan</h5>
                                <p class="card-text">{{ formatCurrency(totalIncome) }} IDR</p>
                            </div>
                        </div>
                        <div class="card text-bg-danger bg-opacity-10 text-danger mb-3">
                            <div class="card-body">
                                <h5 class="card-title">Jumlah Pengeluaran</h5>
                                <p class="card-text">{{ formatCurrency(totalExpenses) }} IDR</p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Participant Table -->
                <h4>Peserta</h4>
                <div class="card">
                    <div class="card-body">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Tanggal Bergabung</th>
                                    <th>Nama</th>
                                    <th>Whatsapp</th>
                                    <th>Email</th>
                                    <th>Pendidikan</th>
                                    <th>Status Profil</th>
                                    <th>Status Bekerja</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="participants.length === 0">
                                    <td colspan="7" class="text-center">No results found</td>
                                </tr>
                                <tr v-for="(participant, index) in participants" :key="index">
                                    <td>{{ participant.joinDate }}</td>
                                    <td>{{ participant.name }}</td>
                                    <td>{{ participant.whatsapp }}</td>
                                    <td>{{ participant.email }}</td>
                                    <td>{{ participant.education }}</td>
                                    <td>{{ participant.profileStatus }}</td>
                                    <td>{{ participant.workStatus }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SidebarAdmin from "@/components/SidebarAdmin.vue";
import { ref, onMounted } from 'vue';
import Chart from 'chart.js/auto';

export default {
    components: {
        SidebarAdmin
    },
    setup() {
        const totalUsers = ref(0);
        const totalIncome = ref(0);
        const totalExpenses = ref(0);
        const participants = ref([]);

        // Dummy data for participants
        const loadDummyData = () => {
            participants.value = [];
            totalUsers.value = participants.value.length;
            totalIncome.value = 0; // Example income
            totalExpenses.value = 0; // Example expenses
        };

        // Function to format currency
        const formatCurrency = (value) => {
            return new Intl.NumberFormat('id-ID', {
                style: 'currency',
                currency: 'IDR'
            }).format(value);
        };

        // Chart setup
        const setupChart = () => {
            const ctx = document.getElementById('educationChart').getContext('2d');
            new Chart(ctx, {
                type: 'pie',
                data: {
                    labels: ['SMA', 'Sekolah Kejuruan', 'Diploma', 'Sarjana'],
                    datasets: [{
                        label: 'Pendidikan',
                        data: [10, 15, 25, 50], // Example data for education levels
                        backgroundColor: ['#007bff', '#28a745', '#ffc107', '#dc3545']
                    }]
                },
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            display: true,
                            position: 'top'
                        }
                    }
                }
            });
        };

        onMounted(() => {
            loadDummyData();
            setupChart();
        });

        return {
            totalUsers,
            totalIncome,
            totalExpenses,
            participants,
            formatCurrency
        };
    },
    methods: {
        logout() {
            localStorage.removeItem("auth_token");
            this.$router.push("/login");
        }
    }
};
</script>

<style scoped>
.sidebar {
    width: 250px;
}

.sidebar.collapsed {
    width: 80px !important;
}

.bg-light {
    background-color: #f8f9fa !important;
}

.nav-link {
    font-size: 1.1rem;
}

button i {
    font-size: 1.2rem;
}

.navbar-brand img {
    height: 30px;
}

.card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-title {
    font-size: 1.1rem;
}

.table th, .table td {
    vertical-align: middle;
}
</style>
