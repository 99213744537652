import { createRouter, createWebHistory } from 'vue-router'

// users
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import RegisView from '../views/RegisView.vue'
import DashboardView from '../views/DashboardView.vue'
import ClassesView from '../views/ClassesView.vue'
import ModuleView from '../views/ModuleView.vue'

// admin
import DashboardAdmin from '../views/admin/DashboardAdmin.vue'
import ClassAdmin from '../views/admin/ClassAdmin.vue'
import ModuleAdmin from '../views/admin/ModuleAdmin.vue'
import EmailBlastAdmin from '../views/admin/EmailBlastAdmin.vue'
import UsersAdmin from '../views/admin/UsersAdmin.vue'
import FinanceAdmin from '../views/admin/FinanceAdmin.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/daftar',
    name: 'regis',
    component: RegisView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView
  },
  {
    path: '/kelas',
    name: 'classes',
    component: ClassesView
  },
  {
    path: '/kelassaya',
    name: 'module',
    component: ModuleView
  },
  {
    path: '/admin/dashboard',
    name: 'dashboardadmin',
    component: DashboardAdmin
  },
  {
    path: '/admin/kelas',
    name: 'classadmin',
    component: ClassAdmin
  },
  {
    path: '/admin/module',
    name: 'ModuleAdmin',
    component: ModuleAdmin
  },
  {
    path: '/admin/email-blast',
    name: 'emailBlastAdmin',
    component: EmailBlastAdmin
  },
  {
    path: '/admin/finance',
    name: 'financeAdmin',
    component: FinanceAdmin
  },
  {
    path: '/admin/users',
    name: 'usersAdmin',
    component: UsersAdmin
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
