<template>
    <div class="row bg-light m-0 p-0 w-100 overflow-hidden" style="height: 100dvh;">
        <SidebarAdmin/>
        <!-- Main Content -->
        <div class="col m-0 p-0 h-100 overflow-y-scroll">
            <!-- Navbar -->
            <nav class="navbar bg-white border-bottom">
                <div class="container d-flex justify-content-between">
                    <span class="navbar-brand mb-0 h1">
                        <img src="../../assets/logo.svg" alt="logo" height="30px" class="me-2">
                        <b>Genbox</b><span class="fw-light">Admin</span>
                    </span>
                    <button @click="logout" class="btn btn-outline-danger">Logout</button>
                </div>
            </nav>

            <!-- Content Placeholder -->
            <div class="container p-4">
                <h2>Create Module Group</h2>
                <form @submit.prevent="submitForm">
                    <!-- ModuleGroup Form -->
                    <div class="mb-3">
                        <label for="name" class="form-label">Module Group Name</label>
                        <input type="text" v-model="moduleGroup.name" class="form-control" id="name" required>
                    </div>
                    <div class="mb-3">
                        <label for="description" class="form-label">Description</label>
                        <textarea v-model="moduleGroup.description" class="form-control" id="description" required></textarea>
                    </div>
                    <div class="mb-3">
                        <label for="price" class="form-label">Price</label>
                        <input type="number" v-model="moduleGroup.price" class="form-control" id="price" required>
                    </div>

                    <h4 class="mt-4">Modules</h4>
                    <div v-for="(module, index) in modules" :key="index" class="border p-3 mb-3">
                        <div class="mb-3">
                            <label :for="'category' + index" class="form-label">Category</label>
                            <input type="text" v-model="module.category" class="form-control" :id="'category' + index" required>
                        </div>
                        <div class="mb-3">
                            <label :for="'name' + index" class="form-label">Module Name</label>
                            <input type="text" v-model="module.name" class="form-control" :id="'name' + index" required>
                        </div>
                        <div class="mb-3">
                            <label :for="'description' + index" class="form-label">Description</label>
                            <textarea v-model="module.description" class="form-control" :id="'description' + index" required></textarea>
                        </div>
                        <div class="mb-3">
                            <label :for="'docs' + index" class="form-label">Docs</label>
                            <textarea v-model="module.docs" class="form-control" :id="'docs' + index"></textarea>
                        </div>
                        <div class="mb-3">
                            <label :for="'stack' + index" class="form-label">Stack</label>
                            <input type="text" v-model="module.stack" class="form-control" :id="'stack' + index">
                        </div>
                        <div class="mb-3">
                            <label :for="'src' + index" class="form-label">Source Video</label>
                            <input type="file" @change="handleFileUpload($event, index)" accept="video/mp4,video/quicktime" class="form-control" :id="'src' + index">
                        </div>

                        <!-- Show Remove Module button only if there is more than one module -->
                        <button v-if="modules.length > 1" type="button" class="btn btn-danger" @click="confirmRemoveModule(index)">Remove Module</button>
                    </div>

                    <button type="button" class="btn btn-primary me-2" @click="addModule">Add Module</button>
                    <button type="submit" class="btn btn-success">Submit</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import SidebarAdmin from "@/components/SidebarAdmin.vue";
import Swal from "sweetalert2";

export default {
    data() {
        return {
            isCollapsed: false,
            moduleGroup: {
                name: "",
                description: "",
                price: 0
            },
            modules: [
                {
                    category: "",
                    name: "",
                    description: "",
                    docs: "",
                    stack: "",
                    src: ""
                }
            ]
        };
    },
    components: {
        SidebarAdmin
    },
    methods: {
        logout() {
            localStorage.removeItem("auth_token");
            this.$router.push("/login");
        },
        addModule() {
            this.modules.push({
                category: "",
                name: "",
                description: "",
                docs: "",
                stack: "",
                src: null // Initialize src as null for file upload
            });
        },
        confirmRemoveModule(index) {
            Swal.fire({
                title: "Are you sure?",
                text: "This will remove the module.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Yes, remove it!"
            }).then((result) => {
                if (result.isConfirmed) {
                    this.removeModule(index);
                }
            });
        },
        removeModule(index) {
            if (this.modules.length > 1) {
                this.modules.splice(index, 1);
                Swal.fire("Removed!", "The module has been removed.", "success");
            }
        },
        handleFileUpload(event, index) {
            this.modules[index].src = event.target.files[0]; // Store file object for upload
        },
        async submitForm() {
            const formData = new FormData();
            formData.append("category", this.moduleGroup.category);
            formData.append("name", this.moduleGroup.name);
            formData.append("desc", this.moduleGroup.description);
            formData.append("price", this.moduleGroup.price);

            this.modules.forEach((module, index) => {
                formData.append(`modules[${index}][category]`, module.category);
                formData.append(`modules[${index}][name]`, module.name);
                formData.append(`modules[${index}][desc]`, module.description);
                formData.append(`modules[${index}][docs]`, module.docs || "");
                formData.append(`modules[${index}][stack]`, module.stack || "");
                if (module.src) {
                    formData.append(`modules[${index}][src]`, module.src); // Append video file if it exists
                }
            });

            try {
                const response = await axios.post("https://api.genbox.academy/public/api/module-groups", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                });
                if (response.status === 201) {
                    Swal.fire("Success!", "Module Group created successfully!", "success");
                    this.resetForm();
                }
            } catch (error) {
                console.error("Error submitting form:", error);
                Swal.fire("Error", "Failed to create Module Group. Please try again.", "error");
            }
        },
        resetForm() {
            this.moduleGroup = {
                name: "",
                description: "",
                price: 0
            };
            this.modules = [
                {
                    category: "",
                    name: "",
                    description: "",
                    docs: "",
                    stack: "",
                    src: null
                }
            ];
        }
    }
};
</script>

<style scoped>
.sidebar {
    width: 250px;
}

.sidebar.collapsed {
    width: 80px !important;
}

.bg-light {
    background-color: #f8f9fa !important;
}

.nav-link {
    font-size: 1.1rem;
}

button i {
    font-size: 1.2rem;
}

.navbar-brand img {
    height: 30px;
}

.container {
    max-width: 800px;
}
</style>
