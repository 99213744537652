<template>    
    <!-- Sidebar -->
    <div :class="['sidebar', 'm-0', 'p-0', 'bg-white', 'h-100', 'border-end', 'border', { 'collapsed': isCollapsed }]" style="transition: width 0.3s;">
        <div class="d-flex flex-column align-items-start p-3 h-100">
            <button @click="toggleCollapse" class="btn btn-outline-secondary w-100 mb-4">
                <i :class="isCollapsed ? 'fas fa-chevron-right' : 'fas fa-chevron-left'"></i>
            </button>
            <small>Analyst Bisnis</small>
            <hr class="w-100">
            <ul class="nav flex-column w-100">
                <li class="nav-item mb-3">
                    <router-link to="/admin/dashboard" class="nav-link text-dark d-flex align-items-center">
                        <i class="fas fa-tachometer-alt me-3"></i>
                        <span v-if="!isCollapsed">Dashboard</span>
                    </router-link>
                </li>
                <li class="nav-item mb-3">
                    <router-link to="/admin/email-blast" class="nav-link text-dark d-flex align-items-center">
                        <i class="fa-solid fa-fire me-3"></i>
                        <span v-if="!isCollapsed">Email Blast</span>
                    </router-link>
                </li>
                <li class="nav-item mb-3">
                    <router-link to="/admin/finance" class="nav-link text-dark d-flex align-items-center">
                        <i class="fa-solid fa-money-bill me-3"></i>
                        <span v-if="!isCollapsed">Finance</span>
                    </router-link>
                </li>
                <li class="nav-item mb-3">
                    <router-link to="/admin/users" class="nav-link text-dark d-flex align-items-center">
                        <i class="fa-solid fa-users me-3"></i>
                        <span v-if="!isCollapsed">Users</span>
                    </router-link>
                </li>
            </ul>
            <small>Management Kelas</small>
            <hr class="w-100">
            <ul class="nav flex-column w-100">
                <li class="nav-item mb-3">
                    <router-link to="/admin/kelas" class="nav-link text-dark d-flex align-items-center">
                        <i class="fas fa-book-open me-3"></i>
                        <span v-if="!isCollapsed">Class</span>
                    </router-link>
                </li>
                <li class="nav-item mb-3">
                    <router-link to="/admin/module" class="nav-link text-dark d-flex align-items-center">
                        <i class="fas fa-cubes me-3"></i>
                        <span v-if="!isCollapsed">Module Group</span>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            isCollapsed: false,
        };
    },
    methods: {
        toggleCollapse() {
            this.isCollapsed = !this.isCollapsed;
        },
    }
};
</script>