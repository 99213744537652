<template>
    <div class="row w-100" style="height: 100dvh;">
        <div class="col-6 bg-dark2 text-white h-100 d-flex justify-content-center align-items-center">
            <div class="position-relative d-flex gap-5 align-items-center">
                <img src="../assets/logo.svg" alt="logo" height="80px">
                <div>
                    <h1>Daftar Kelas</h1>
                    <p class="fw-light">Login jika sudah ada akun</p>
                    <router-link class="text-white text-decoration-none" to="/login">Login</router-link>
                    <span class="mx-2">|</span>
                    <router-link class="text-white text-decoration-none" to="/">Kembali ke awal</router-link>
                </div>
            </div>
        </div>
        <div class="col-6 h-100 d-flex align-items-center px-5">
            <div class="w-75">
                <form @submit.prevent="register">
                    <div class="mb-3">
                        <label for="name" class="form-label">Full Name</label>
                        <input type="text" v-model="form.name" class="form-control" id="name" required>
                    </div>
                    <div class="mb-3">
                        <label for="username" class="form-label">Username</label>
                        <input type="text" v-model="form.username" class="form-control" id="username" required>
                    </div>
                    <div class="mb-3">
                        <label for="email" class="form-label">Email Address</label>
                        <input type="email" v-model="form.email" class="form-control" id="email" required>
                    </div>
                    <div class="mb-3">
                        <label for="password" class="form-label">Password</label>
                        <input type="password" v-model="form.password" class="form-control" id="password" required>
                    </div>
                    <div class="mb-3">
                        <label for="confirmPassword" class="form-label">Confirm Password</label>
                        <input type="password" v-model="form.confirmPassword" class="form-control" id="confirmPassword" required>
                    </div>
                    <button type="submit" class="btn btn-neon text-white me-2" :disabled="loading">
                        <span v-if="loading" class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        <span v-if="!loading">Register</span>
                        <span v-else>Registering...</span>
                    </button>
                </form>
                <div v-if="errors.length" class="alert alert-danger mt-3">
                    <ul>
                        <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "RegisView",
    data() {
        return {
            form: {
                name: "",
                username: "",
                email: "",
                password: "",
                confirmPassword: "",
            },
            errors: [],
            loading: false
        };
    },
    methods: {
        async register() {
            this.errors = [];
            this.loading = true;

            // Validate that password and confirmPassword match
            if (this.form.password !== this.form.confirmPassword) {
                this.errors.push("Password and Confirm Password do not match.");
                this.loading = false;
                return;
            }

            try {
                // Send registration request to API
                const response = await axios.post("https://api.genbox.academy/public/api/register", {
                    name: this.form.name,
                    username: this.form.username,
                    email: this.form.email,
                    password: this.form.password,
                    password_confirmation: this.form.confirmPassword,
                });

                if (response.status === 201) {
                    this.$router.push("/login"); // Redirect to login page
                }
            } catch (error) {
                // Handle errors from the API
                if (error.response && error.response.data.errors) {
                    this.errors = Object.values(error.response.data.errors).flat();
                } else {
                    this.errors.push("An error occurred. Please try again.");
                }
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped>
.bg-dark2 {
    background-image: url(../assets/btcmp.jpg);
    background-position: 50%;
    background-size: cover;
    position: relative;
    z-index: 99;
}

.bg-dark2:before {
    content: "";
    position: absolute;
    display: block;
    background-color: #222831fb;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
}
</style>
