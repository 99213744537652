<template>
    <div class="row bg-light m-0 p-0 w-100 overflow-hidden" style="height: 100dvh;">
        <SidebarRoot/>

        <!-- Main Content -->
        <div class="col m-0 p-0 h-100 overflow-y-scroll">
            <!-- Navbar -->
            <nav class="navbar bg-white border-bottom">
                <div class="container d-flex justify-content-between">
                    <span class="navbar-brand mb-0 h1">
                        <img src="../assets/logo.svg" alt="logo" height="30px" class="me-2">
                        <b>Genbox</b><span class="fw-light">Class</span>
                    </span>
                    <button @click="logout" class="btn btn-outline-danger">Logout</button>
                </div>
            </nav>

            <!-- Content Placeholder -->
            <div class="container p-4">
                <h2 class="mb-3">Module Groups</h2>
                <hr class="my-3">
                <!-- Loading Spinner -->
                <div v-if="loading" class="text-center my-5 d-flex align-items-center">
                    <span class="spinner-border me-2" role="status" aria-hidden="true"></span>
                    <span>Loading...</span>
                </div>
                
                <!-- Module Groups List -->
                <div v-else>
                    <div v-for="group in moduleGroups" :key="group.id" class="card shadow-lg mb-3 col-4">
                        <div class="card-body">
                            <h5 class="card-title">{{ group.name }}</h5>
                            <p class="card-text">{{ group.description }}</p>
                            <span class="badge bg-primary">Harga: IDR {{ group.price }}</span>
                            <button class="mt-3 btn btn-outline-primary w-100">Beli</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SidebarRoot from '@/components/SidebarRoot.vue';
import axios from 'axios';

export default {
    data() {
        return {
            moduleGroups: [],
            loading: true,
        };
    },
    methods: {
        async fetchModuleGroups() {
            try {
                const response = await axios.get("https://api.genbox.academy/public/api/module-groups");
                this.moduleGroups = response.data;
            } catch (error) {
                console.error("Error fetching module groups:", error);
            } finally {
                this.loading = false;
            }
        },
        logout() {
            localStorage.removeItem("auth_token");
            this.$router.push("/login");
        }
    },
    created() {
        this.fetchModuleGroups();
    },
    components: {
        SidebarRoot
    }
};
</script>

<style>
.sidebar {
    width: 250px;
}

.sidebar.collapsed {
    width: 80px !important;
}

.bg-light {
    background-color: #f8f9fa !important;
}

.nav-link {
    font-size: 1.1rem;
}

button i {
    font-size: 1.2rem;
}

.navbar-brand img {
    height: 30px;
}

.card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: none;
}
</style>
