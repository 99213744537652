<template>
    <div class="row bg-light m-0 p-0 w-100 overflow-hidden" style="height: 100dvh;">        
        <SidebarAdmin/>

        <!-- Main Content -->
        <div class="col m-0 p-0 h-100 overflow-y-scroll">
            <!-- Navbar -->
            <nav class="navbar bg-white border-bottom">
                <div class="container d-flex justify-content-between">
                    <span class="navbar-brand mb-0 h1">
                        <img src="../../assets/logo.svg" alt="logo" height="30px" class="me-2">
                        <b>Genbox</b><span class="fw-light">Admin</span>
                    </span>
                    <button @click="logout" class="btn btn-outline-danger">Logout</button>
                </div>
            </nav>

            <!-- Content Placeholder -->
            <div class="container p-4">
                <h3>Genbox Finance</h3>
                
                <div class="d-flex gap-4">
                    <div class="card text-bg-primary bg-opacity-10 text-primary mb-3 w-25">
                        <div class="card-body">
                            Saldo Sekarang
                            <h4>{{ formatCurrency(sekarang) }}</h4>
                        </div>
                    </div>
                    <div class="card text-bg-success bg-opacity-10 text-success mb-3 w-25">
                        <div class="card-body">
                            Jumlah Pemasukan
                            <h4>{{ formatCurrency(masuk) }}</h4>
                        </div>
                    </div>
                    <div class="card text-bg-danger bg-opacity-10 text-danger mb-3 w-25">
                        <div class="card-body">
                            Jumlah Pengeluaran
                            <h4>{{ formatCurrency(keluar) }}</h4>
                        </div>
                    </div>
                </div>

                <!-- Button to open modal -->
                <button type="button" class="btn btn-primary my-3" data-bs-toggle="modal" data-bs-target="#dataModal">
                    Masukan Riwayat
                </button>

                <!-- Table for financial data -->
                <table class="table table-bordered shadow">
                    <thead class="text-center">
                        <tr>
                            <th rowspan="2">#</th>
                            <th rowspan="2">Riwayat</th>
                            <th rowspan="2">Tanggal</th>
                            <th rowspan="2">Type</th>
                            <th colspan="2">Uang</th>
                            <th rowspan="2">Action</th>
                        </tr>
                        <tr>
                            <th>Masuk</th>
                            <th>Keluar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in data" :key="item._id">
                            <td class="text-center">{{ index + 1 }}</td>
                            <td class="text-center">{{ item.riwayat }}</td>
                            <td class="text-center">{{ item.tanggal }}</td>
                            <td>{{ item.type }}</td>
                            <td class="text-end">{{ formatCurrency(item.income) }}</td>
                            <td class="text-end">{{ formatCurrency(item.out) }}</td>
                            <td class="text-center">
                                <button class="btn btn-outline-danger" @click="deleteItem(item._id)">Hapus</button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <!-- Modal for input data -->
                <div class="modal fade" id="dataModal" tabindex="-1" aria-labelledby="dataModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="dataModalLabel">Input Data</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <form @submit.prevent="submitData">
                                    <div class="mb-3">
                                        <label class="form-label">Riwayat</label>
                                        <input v-model="riwayat" type="text" class="form-control"/>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Tanggal</label>
                                        <input v-model="tanggal" type="date" class="form-control"/>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Type</label>
                                        <select v-model="type" class="form-select">
                                            <option value="">Pilih Opsi</option>
                                            <option value="Barang">Barang</option>
                                            <option value="Jasa">Jasa</option>
                                            <option value="Investasi">Investasi</option>
                                            <option value="Bootcamp">Bootcamp</option>
                                            <option value="Privasi">Private</option>
                                            <option value="Regular">Lainnya</option>
                                        </select>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Uang Masuk</label>
                                        <input v-model="income" type="number" class="form-control"/>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Uang Keluar</label>
                                        <input v-model="out" type="number" class="form-control"/>
                                    </div>
                                    <button type="submit" class="btn btn-primary">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SidebarAdmin from "@/components/SidebarAdmin.vue";

export default {
    data() {
        return {
            sekarang: 0,
            masuk: 0,
            keluar: 0,
            data: [],
            riwayat: '',
            tanggal: '',
            type: '',
            income: 0,
            out: 0
        };
    },
    components: {
        SidebarAdmin
    },
    methods: {
        formatCurrency(value) {
            return new Intl.NumberFormat('id-ID', {
                style: 'currency',
                currency: 'IDR'
            }).format(value);
        },
        logout() {
            localStorage.removeItem("auth_token");
            this.$router.push("/login");
        },
        submitData() {
            // Push new data to `data` array
            const newItem = {
                _id: Date.now(), // Temporary ID
                riwayat: this.riwayat,
                tanggal: this.tanggal,
                type: this.type,
                income: parseFloat(this.income),
                out: parseFloat(this.out)
            };
            this.data.push(newItem);

            // Update total balance, income, and outcome
            this.masuk += newItem.income;
            this.keluar += newItem.out;
            this.sekarang = this.masuk - this.keluar;

            // Clear form fields
            this.riwayat = '';
            this.tanggal = '';
            this.type = '';
            this.income = 0;
            this.out = 0;

            // Close modal
            // const modal = new bootstrap.Modal(document.getElementById('dataModal'));
            // modal.hide();
        },
        deleteItem(id) {
            const item = this.data.find(d => d._id === id);
            if (item) {
                this.masuk -= item.income;
                this.keluar -= item.out;
                this.sekarang = this.masuk - this.keluar;
                this.data = this.data.filter(d => d._id !== id);
            }
        }
    }
};
</script>

<style>
.sidebar {
    width: 250px;
}

.sidebar.collapsed {
    width: 80px !important;
}

.bg-light {
    background-color: #f8f9fa !important;
}

.nav-link {
    font-size: 1.1rem;
}

button i {
    font-size: 1.2rem;
}

.navbar-brand img {
    height: 30px;
}

.card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: none;
}
</style>
